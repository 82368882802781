<template>
  <div class="box">
    <van-nav-bar left-arrow fixed :border="false" @click-left="onClickLeft" />
    <!-- <div class="Ge-img1">
      <img style="height: 60px;" src="@/assets/img/Invitation/yaoqingTitle.png" alt="" />
    </div> -->
    <div style="height: 160px;"></div>
    <div class="img2">
      <!-- <img class="img2-img" src="@/assets/img/Invitation/yaoqing.png" alt="" /> -->
      <img class="img2-img" src="./bgImgCenter.png" alt="" />
      <img class="img2-code" :src="srcCode" />
      <span class="img2-order">邀请口令：{{ numCode }}</span>
      <div class="img2-botton" @click="buttonshow">立即邀请</div>
      <div class="img2-copy">
        <img src="./copyIcon.png" alt="">
        <span class="xtext" :data-clipboard-text="appUrl" @click="copyAppUrl">复制APP下载链接</span>
      </div>
    </div>
    <div class="ftext">
      <!-- <label style="display: block">
        <textarea id="textArea2" style="opacity: 0; width: 1px; height: 1px"></textarea>
      </label> -->
      <span class="ftext1" id="text2">{{ copyMsg }}</span>
      <div class="ftextD">
        <img src="./copyIcon.png" alt="">
        <span class="ftext2" @click="copyWord" :data-clipboard-text="copyMsg">复制</span>
      </div>
    </div>
    <!--  <div>
      <span class="xtext" :data-clipboard-text="appUrl" @click="copyAppUrl">复制APP下载链接</span>
    </div> -->
    <!-- <div class="botton" @click="buttonshow">一键分享</div> -->
    <!-- <div class="pink">
      <div class="bgimg"></div>
      <div style="height: 20px"></div>
      <div class="text">
        <div>
          <div style="text-align: center">
            <span class="number">{{ initData.inviteCount }}</span>人
          </div>
          <div class="htz">邀请注册总人数</div>
        </div>
        <div class="vertical"></div>
        <div>
          <div style="text-align: center">
            <span class="number">{{ initData.orderCount }}</span>人
          </div>
          <div class="htz">已下单</div>
        </div>
      </div>
    </div> -->
    
    <!-- <div class="pinktext">
      <div class="bgimg"></div>
      <div class="text">
        <div class="textItem">
          <img class="itemImg" src="./bgImgFirst.png" alt="">
          <p>1. 分享海报或
            APP给好友</p>
        </div>
        <div class="textItem">
          <img class="itemImg" src="./bgImgSecond.png" alt="">
          <p>2. 好友注册
            登录成功</p>
        </div>
        <div class="textItem">
          <img class="itemImg" src="./bgImgThird.png" alt="">
          <p>3.邀请好友
            开店获得相应奖励</p>
        </div>
      </div>
    </div> -->

    <div class="footer">
      <p class="footer-text">最终解释权归Win生活所有</p>
    </div>
    <van-popup v-model="showPop" round style="width: 90%">
      <div class="diaing">
        <div class="concent">
          <div class="title">微信</div>
          <div class="img3" ref="imageTofile">
            <!-- <img class="img2-img" src="@/assets/img/Invitation/yaoqing.png" alt="" /> -->
            <img class="img3-img" src="./more.png" alt="" />
            <span class="img3-order img3-container">
              <span>邀请口令：</span>
              <span>{{ numCode }}</span>
            </span>
            <img class="img3-code" :src="srcCode" />
          </div>
          <div class="list">
            <div @click="shareOther('0')">
              <img style="width: 40px" src="../../assets/img/Invitation/weixin.png" alt="" /><br />
              <span class="weixin">微信好友</span>
            </div>
            <div @click="shareOther('1')" style="margin-left: -7px">
              <img style="width: 40px" src="../../assets/img/Invitation/pengyouquan.png" alt="" /><br />
              <span>朋友圈</span>
            </div>
            <div @click="shareOther('2')">
              <img src="../../assets/img/Invitation/qq.png" alt="" /><br />
              <span class="qq">QQ</span>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { inviteData, getErCode, upload } from "@/api/invitationRegister" // upload
// import base from './index'
// import { Toast } from 'vant'
export default {
  data() {
    return {
      show: false,
      initData: {},
      srcCode: "",
      numCode: "", //验证码
      showPop: false, //显示分享
      appUrl: "别测了，上线后就是url",
      copyMsg:
        "别人发红包总是抢不到？来Win生活消费，买啥都有返利！淘宝下单还有大红包可以抽，100%中奖！",
      dataUrl: ''
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session);
    this.getCode();
    this.inviteData();
  },

  mounted() {

  },

  methods: {
    // 复制APP下载链接
    copyAppUrl() {
      var clipboard = new this.Clipboard(".xtext");
      clipboard.on("success", () => {
        this.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast("复制失败");
        // 释放内存
        clipboard.destroy();
      })
    },

    // 分享给别人
    shareOther(num) {
      let url = this.dataUrl
      let params = {
        num: num,
        url: url,
        title: "邀请注册",
      }
      //  分享给别人
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.shareOther.postMessage(params);
      }
      if (isAndroid) {
        window.android.shareImg(JSON.stringify(params))
      }
    },

    // 原生返回
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    // 首页数据
    inviteData() {
      inviteData().then((res) => {
        if (res.data.code === 0) {
          this.initData = res.data.data;
          if (this.initData.appUrl != "") {
            this.appUrl = this.initData.appUrl;
          }
          if (this.initData.scanDescribe != "") {
            this.copyMsg = this.initData.scanDescribe;
          }
        }
      });
    },
    // 复制
    copyWord() {
      var clipboard = new this.Clipboard(".ftext2");
      clipboard.on("success", () => {
        this.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast("复制失败")
        // 释放内存
        clipboard.destroy()
      })
    },

    // 获取二维码方法
    getCode() {
      let params = {
        // http://192.168.0.100:8082/login#/login
        content: "http://mall.hfzlwl.com/app/#/login",
        width: 200,
        height: 200,
        //  https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdc1533d3aaa355afe4517287ecd6a774d33eb7b0ea38abef29f8c4ad0e9b101e
        logoUrl: "",
        logoWidth: 50,
        logoHeight: 50,
      };
      getErCode(params).then((res) => {
        if (res.data.code === 0) {
          this.srcCode = res.data.data.downloadUrl;
          this.numCode = res.data.data.invite_code; //保存验证码，传给原生
          
        } else {
          this.$toast("请求数据失败");
          this.$Toast(res.data.message);
        }
      })
    },

    // 点击分享
    buttonshow() {
      this.showPop = true;
      this.$nextTick(() => {
        this.toImage()
      })
    },

    daingshow() {
      this.show = false;
    },

    toImage() {
      const canvas = document.createElement('canvas')
      const canvasBox = this.$refs.imageTofile
      const width = parseInt(window.getComputedStyle(canvasBox).width)
      const height = parseInt(window.getComputedStyle(canvasBox).height)
      canvas.width = width * 1
      canvas.height = height * 1
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      const context = canvas.getContext('2d')
      context.scale(2, 2)
      const options = {
        backgroundColor: 'white', // 截图背景，不给则无
        useCORS: true, // 允许跨域，比如有的请求或者图片会跨域，建议都设置为true
        scale: 1 // 放大倍数
        // height: document.getElementById('touched').scrollHeight, // 宽高为全屏
        // windowHeight: document.getElementById('touched').scrollHeight // 宽高为全屏
      }
      html2canvas(canvasBox, options).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png')
        this.transformImg(dataURL)
      })
    },

    transformImg(base) {
      var img = this.base64ToImg(base, 'ali.jpg')
      let formData = new window.FormData()
      formData.append('file', img)
      formData.append('fileType', 'imgage/png'),
        formData.append('dir', 'material')
      upload(formData).then(res => {
        if (res.data.code === 0) {
          this.dataUrl = res.data.data
          this.$GeHideLoading()
        }
      })
    },

    base64ToImg(dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  padding-bottom: 15px;
  // background: linear-gradient(to bottom, #71D7FF, #4F7BFE, #237DF2, #1F37CF);
  background: url('./bgImg.png') no-repeat;
  background-color: rgb(254, 165, 152);
  background-size: 100%;
  position: relative;
  height: 100%;

  /deep/ .van-nav-bar--fixed {
    background: rgba(255, 255, 255, 0);
  }

  /deep/ .van-nav-bar__left .van-icon-arrow-left {
    color: #333333;
  }

  .Ge-img1 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  .img2 {
    width: 90%;
    margin: auto;
    position: relative;

    &-img {
      width: 100%;
    }

    &-order {
      color: #ed432c;
      font-weight: bold;
      position: absolute;
      left: 40%;
      top: 52%;
      transform: translate(-50%, -50%);
      font-size: 12px;
    }

    &-container {
      display: flex;
      align-items: center;
    }

    &-code {
      width: 40%;
      height: 30%;
      position: absolute;
      left: 50%;
      top: 10%;
      transform: translateX(-50%);
    }

    &-copy {
      position: absolute;
      left: 50%;
      bottom: 3%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;

      img {
        width: 15px;
        height: 15px;
      }

      .xtext {
        // display: block;
        overflow-wrap: break-word;
        color: rgba(203, 74, 30, 1);
        font-size: 12px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 17px;
        // text-align: left;
        margin: 10px;
      }
    }

    &-botton {
      width: 65%;
      height: 45px;
      margin: 15px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, #FFE9AC, #FFC8A3);
      color: #af4500;
      border-radius: 29px;
      border: 4px solid rgb(254, 162, 127);
      box-shadow: inset 0px 5px 0px 0px rgb(255, 231, 171);
      position: absolute;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
    }

  }

  .ftext {
    margin-top: 15px;
    color: #E5613E;
    border-radius: 12px;
    border: 1px solid rgba(254, 128, 81, 0.27);
    // background-color: rgba(255, 255, 255, 0.2);
    background: linear-gradient(to right, #FFF6E5, #FFE3B0);
    width: 90%;
    margin-left: 5%;
    display: flex;
    align-items: center;

    .ftext1 {
      width: 70%;
      margin: 6%;
    }

    .ftextD {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      .ftext2 {
        margin-left: 5%;
        margin-top: 10%;
      }
    }
  }

  /* .botton {
    width: 90%;
    height: 45px;
    margin: 15px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #ffa77f, #fc6a41);
    color: #af4500;
    border-radius: 99px;
    box-shadow: 0 5px 3px #fc6a41;
  } */

  /* .xtext {
    display: block;
    overflow-wrap: break-word;
    color: rgba(203, 74, 30, 1);
    font-size: 12px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin: 10px 0 0px 42%;
  } */

  .pink {
    height: 140px;
    margin: 15px;
    padding: 0 10px;
    border-radius: 12px;
    // background-color: #fff6f6;
    background: linear-gradient(to right, #FFF1CE, #FFEEE3);
    position: relative;

    .bgimg {
      width: 181px;
      height: 44px;
      margin: 0 auto;
      // background: url('~@/assets/img/Invitation/zhuanshu.png') no-repeat;
      background: url('./bgImgOne.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 44px;
      font-size: 20px;
      color: #fff;
      // position: absolute;
      // left: 27%;
      // top: -18px;
    }

    .text {
      display: flex;
      justify-content: space-around;
      background: #FFF2E9;
      border-radius: 15px;

      .number {
        color: #FE3B32;
        font-size: 25px;
      }

      .htz {
        color: #bbbbbb;
        font-size: 17px;
      }

      .vertical {
        width: 1px;
        height: 33px;
        background-color: #edc5bd;
        transform: translateX(-25px);
      }
    }
  }

  .pinktext {
    height: 180px;
    margin: 15px;
    padding-top: 10px;
    border-radius: 8px;
    background: linear-gradient(to right, #FFF1CE, #FFEEE3);

    .bgimg {
      width: 90px;
      height: 24px;
      margin: 0 auto;
      font-size: 22px;
      // background: url('~@/assets/img/Invitation/gonglv.png') no-repeat;
      background: url('./bgFun.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 44px;
      color: #fff;
      // position: absolute;
      // left: 27%;
      // top: -29px;
    }

    .text {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      margin-top: 15px;

      /* .paragraph2 {
        width: 310px;
        height: 125px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 13px;
        line-height: 25px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 35px 0 0 15px;
      } */
      .textItem {
        .itemImg {
          width: 90px;
          height: 90px;
        }

        p {
          width: 90px;
          font-size: 12px;
        }
      }
    }
  }

  .footer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(254, 88, 94);
  }

  .diaing {
    display: flex;
    align-items: center;
    justify-content: center;

    .concent {
      border-radius: 16px;
      background-color: #fff;

      .img3 {
        width: 75%;
        margin: auto;
        position: relative;

        &-img {
          width: 100%;
        }

        &-order {
          color: #ed432c;
          font-weight: bold;
          position: absolute;
          left: 40%;
          top: 62%;
          transform: translate(-50%, -50%);
          font-size: 12px;
        }

        &-container {
          display: flex;
          align-items: center;
        }

        &-code {
          width: 40%;
          height: 26%;
          position: absolute;
          left: 50%;
          top: 30%;
          transform: translateX(-50%);
        }

      }

      .title {
        font-size: 25px;
        color: #333333;
        text-align: center;
        margin: 10px 0;
      }

      .list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 0 20px;

        .qq {
          display: inline-block;
          // text-align: center;
          transform: translateX(8px);
        }

        .weixin {
          display: inline-block;
          transform: translateX(-7px);
        }
      }

      .xxx {
        transform: translateY(40px);
        text-align: center;
      }
    }
  }
}
</style>
