import { fetchGet, fetchPost } from '../../router/https'
// 首页数据
function inviteData(data) {
    return fetchGet("/mallapp/userinfo/fansData", data)
}
// 获取二维码 /mallapp/userinfo/generatePoster
function getErCode(data) {
    return fetchGet("/mallapp/userinfo/generatePoster", data)
}

// 上传文件
const upload = (param) => fetchPost('/mallapp/feedbackrelease/uploadFile', param)

export {
    inviteData,
    getErCode,
    upload
} 

  
  